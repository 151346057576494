<template>
    <b-card v-if="'id' in cwo">
        <form @submit.prevent="addManPower">
            <h3>Manpower used</h3>
            <table v-if="'manpowers' in cwo" class="table table-bordered table-cwo">
                <tr>
                    <th class="number">{{ $t("forms.no") }}</th>
                    <th style="width: 300px">{{ $t("forms.name") }}</th>
                    <th>{{ $t("forms.fromDate") }}</th>
                    <th>{{ $t("forms.toDate") }}</th>
                    <th>{{ $t("forms.total") }}</th>
                    <th>{{ $t("forms.remark") }}</th>
                    <th class="actions">{{ $t("forms.actions") }}</th>
                </tr>
                <tr v-for="(item, index) in cwo.manpowers" :key="index">
                    <td>
                        {{ index + 1 }}
                    </td>
                    <td>
                        {{ item.employee }}
                    </td>
                    <td>
                        {{ $helper.formatDate(item.fromDate) + ' ' + $helper.formatTime(item.fromDate) }}
                    </td>
                    <td>
                        {{ $helper.formatDate(item.toDate) + ' ' + $helper.formatTime(item.toDate) }}
                    </td>
                    <td>
                        {{ getTotal(item.fromDate, item.toDate) }}
                    </td>
                    <td>
                        {{ item.remark }}
                    </td>
                    <td>
                        <delete-btn v-if="!item.id" @pressDelete="deleteItem(index)"/>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td>
                        <v-select
                            id="manpowersEmployee"
                            v-model="manpowersEmployee"
                            :options="employees"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.manpowersEmployee.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </td>
                    <td>
                        <datepicker
                            id="manpowersFromDate"
                            v-model="manpowersFromDate"
                            :enableTimePicker="true"
                            format="dd.MM.yyyy HH:mm"
                            class="form-input"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.manpowersFromDate.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </td>
                    <td>
                        <datepicker
                            id="manpowersToDate"
                            v-model="manpowersToDate"
                            :enableTimePicker="true"
                            format="dd.MM.yyyy HH:mm"
                            class="form-input"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.manpowersToDate.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </td>
                    <td>
                        <b-form-input
                            v-model="manpowersTotal"
                            id="manpowersTotal"
                            disabled
                        />
                    </td>
                    <td>
                        <b-form-input v-model="manpowersRemark" id="manpowersRemark"/>
                    </td>
                    <td>
                        <b-button type="submit" variant="gray">
                            <BootstrapIcon icon="plus" size="1x"/>
                            Add
                        </b-button>
                    </td>
                </tr>
            </table>
        </form>
    </b-card>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import vSelect from "vue-select";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";
import Datepicker from "@vuepic/vue-datepicker";

export default {
    components: {
        vSelect,
        DeleteBtn,
        Datepicker,
    },
    props: {
        cwoObject: {Object, required: true},
    },
    setup: () => ({v$: useVuelidate()}),
    validations() {
        return {
            manpowersEmployee: {required},
            manpowersFromDate: {required},
            manpowersToDate: {required},
        };
    },
    data() {
        return {
            cwo: this.cwoObject,
            manpowersEmployee: null,
            manpowersFromDate: null,
            manpowersToDate: null,
            manpowersRemark: null,
            manpowersTotal: null,
            employees: [],
        };
    },
    watch: {
        manpowersFromDate() {
            this.manpowersTotal = this.getTotal(this.manpowersFromDate, this.manpowersToDate)
        },
        manpowersToDate() {
            this.manpowersTotal = this.getTotal(this.manpowersFromDate, this.manpowersToDate)
        }
    },
    created() {
        this.getEmployees();
    },
    methods: {
        async addManPower() {
            const isValid = await this.v$.$validate();
            if (isValid) {
                this.cwo.manpowers.push({
                    employee: this.manpowersEmployee.givenName + ' ' + this.manpowersEmployee.familyName,
                    fromDate: this.manpowersFromDate,
                    toDate: this.manpowersToDate,
                    remark: this.manpowersRemark,
                });
                this.$emit("objectChange", this.cwo.manpowers);
            }
        },
        getEmployees() {
            const params = {
                role: "ROLE_EMPLOYEE",
                pagination: false,
                enabled: true
            };
            this.$Users.getCollection({params}).then((response) => {
                response.data["hydra:member"].forEach((element) => {
                    this.employees.push({
                        label: `${element.givenName} ${element.familyName}`,
                        "@id": element["@id"],
                        id: element.id,
                        givenName: element.givenName,
                        familyName: element.familyName,
                    });
                });
            });
        },
        deleteItem(index) {
            this.cwo.manpowers.splice(index, 1);
        },
        getTotal(from, to) {
            if (from !== null && to !== null) {
                let diff = new Date(to) - new Date(from);
                let hours = Math.floor(diff / 1000 / 60 / 60);
                let minutes = Math.floor((diff / 1000 / 60 / 60 - hours) * 60);
                return hours + " hours " + minutes + " minutes";
            }
        },
    },
};
</script>

<style>
</style>
