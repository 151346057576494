<template>
    <b-card v-if="cwo && 'id' in cwo">
        <form @submit.prevent="addAction">
            <h3>Actions taken</h3>
            <table v-if="'actions' in cwo" class="table table-bordered table-cwo">
                <tr>
                    <th class="number">{{ $t("forms.no") }}</th>
                    <th>{{ $t("forms.description") }}</th>
                    <th>{{ $t("forms.mechanic") }}</th>
                    <th>{{ $t("forms.inspection") }}</th>
                    <th class="actions">{{ $t("forms.actions") }}</th>
                </tr>
                <tr v-for="(item, index) in cwo.actions" :key="index">
                    <td>
                        {{ index + 1 }}
                    </td>
                    <td>
                        {{ item.description }}
                    </td>
                    <td>
                        {{ item.mechanic }}
                    </td>
                    <td>
                        {{ item.inspection }}
                    </td>
                    <td>
                        <delete-btn v-if="!item.id" @pressDelete="deleteItem(index)"/>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td>
                        <b-form-input v-model="actionDescription" id="actionDescription"/>
                        <div
                            class="input-errors"
                            v-for="error of v$.actionDescription.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </td>
                    <td>
                        <v-select
                            id="mechanicSelect"
                            v-model="mechanicSelect"
                            :options="employees"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.mechanicSelect.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </td>
                    <td>
                        <v-select
                            id="inspectionSelect"
                            v-model="inspectionSelect"
                            :options="employees"
                        />
                    </td>
                    <td>
                        <b-button type="submit" variant="gray">
                            <BootstrapIcon icon="plus" size="1x"/>
                            Add
                        </b-button>
                    </td>
                </tr>
            </table>
        </form>
    </b-card>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import vSelect from "vue-select";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";

export default {
    components: {
        vSelect,
        DeleteBtn
    },
    props: {
        cwoObject: {Object, required: true},
    },
    setup: () => ({v$: useVuelidate()}),
    validations() {
        return {
            actionDescription: {required},
            mechanicSelect: {required},
        };
    },
    data() {
        return {
            cwo: this.cwoObject,
            actionDescription: null,
            mechanicSelect: null,
            inspectionSelect: null,
            employees: [],
        };
    },
    created() {
        this.getEmployees();
    },
    methods: {
        async addAction() {
            const isValid = await this.v$.$validate();
            if (isValid) {
                this.cwo.actions.push({
                    description: this.actionDescription,
                    mechanic: this.mechanicSelect.userLetterCode + "-" + this.mechanicSelect.userNumber,
                    inspection: this.inspectionSelect ? this.inspectionSelect.userLetterCode + "-" + this.inspectionSelect.userNumber : null,
                });
                this.$emit("objectChange", this.cwo.actions);
            }
        },
        getEmployees() {
            const params = {
                role: "ROLE_EMPLOYEE",
                pagination: false,
            };
            this.$Users.getCollection({params}).then((response) => {
                response.data["hydra:member"].forEach((element) => {
                    this.employees.push({
                        label: `${element.givenName} ${element.familyName}`,
                        "@id": element["@id"],
                        id: element.id,
                        userLetterCode: element.userLetterCode,
                        userNumber: element.userNumber,
                    });
                });
            });
        },
        deleteItem(index) {
            this.cwo.actions.splice(index, 1)
            this.$emit("objectChange", this.cwo.actions);
        }
    },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>

